<template>
  <div class="meeting-page-wrap">
    <van-config-provider :theme-vars="themeVars">
      <van-sticky>
        <div class="search-wrap">
          <div class="search">
            <van-field
              v-model="search"
              placeholder="输入搜索关键词，多个词之间逗号分隔"
              :right-icon="rightIcon"
              @click-right-icon="handleClear"
              @keypress="handleSearch"
            />
          </div>
        </div>
        <div class="tool-wrap">
          <div class="total"> {{ isFilter ? '筛出' : '共' }}{{ total }}条数据</div>
          <div class="tool">
            <div class="tool-item" @click="handleSort">
              <span>会议年份</span>
              <Sort :type="sortType" />
            </div>
            <div class="tool-item" @click="handleFilter">
              <span>筛选</span>
              <Icon v-if="isFilter" class-prefix="iconfont" name="shaixuan" size="14" color="#0C86FE" @click='filterHandle' />
              <Icon v-else class-prefix="iconfont" name="a-bianzu71" size="16" @click='filterHandle' />
            </div>
          </div>
        </div>
      </van-sticky>
      <van-list
        ref="myList"
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div class="item-wrap" v-for="item in list" :key="item.id" @click="handleClick(item)">
          <div class="logo" v-if="item.logo_stream">
            <img :src="`${base64Map.get(item.logo.split('.').pop())}${item.logo_stream}`" alt="">
          </div>
          <div class="title">{{ item.title }}</div>
          <div class="year">会议年份：{{ item.meeting_year }}</div>
        </div>
      </van-list>
      <van-popup
        v-model:show="show"
        round
        position="bottom"
        :style="{ height: '60%' }"
      >
        <Filter
          :list="filterList"
          @on-reset="resetHandle"
          @on-ok="okHandle"
          @on-change="filterChange"
        >
        </Filter>
      </van-popup>
    </van-config-provider>
  </div>
</template>

<script>
import { Field, ConfigProvider, Icon, List, Sticky, Popup } from 'vant'
import Sort from '@/components/sort/'
import Filter from '../list/components/Filter'
import { getMeetingLabelList, getMeetingFieldList, getMeetingBiomarkerList, getMeetingList } from '@/api/'

export default {
  name: 'Meeting',
  components: {
    [Field.name]: Field,
    [ConfigProvider.name]: ConfigProvider,
    Sort,
    Icon,
    Filter,
    [List.name]: List,
    [Sticky.name]: Sticky,
    [Popup.name]: Popup
  },
  data () {
    return {
      themeVars: {
        cellBackgroundColor: 'transparent',
        cellVerticalPadding: '0px',
        cellHorizontalPadding: '0px',
        fieldPlaceholderTextColor: 'rgba(255,255,255,0.6)',
        fieldInputTextColor: '#fff',
        fieldRightIconColor: '#fff'
      },
      search: '',
      show: false,
      sortType: 'desc',
      total: 0,
      page: 0,
      loading: false,
      finished: false,
      list: [],
      filterList: [],
      filterOpt: {},
      base64Map: new Map([
        ['png', 'data:image/png;base64,'],
        ['jpg', 'data:image/jpg;base64,'],
        ['jpeg', 'data:image/jpeg;base64,'],
        ['gif', 'data:image/gif;base64,'],
        ['bmp', 'data:image/bmp;base64,']
      ])
    }
  },
  computed: {
    rightIcon () {
      return this.search !== '' ? 'clear' : 'search'
    },
    isFilter () {
      return JSON.stringify(this.filterOpt) !== '{}'
    }
  },
  created () {
    const stringMap = {
      0: { title: '领域', key: 'field__id__in', isMultiply: true },
      1: { title: '生物标志物', key: 'topics__bio_marker__id__in', isMultiply: true },
      2: { title: '会议', key: 'meeting_id__in', isMultiply: true }
    }
    Promise.all([getMeetingFieldList(), getMeetingBiomarkerList(), getMeetingLabelList()]).then(res => {
      this.filterList = res.map((item, index) => ({
        title: stringMap[index].title,
        key: stringMap[index].key,
        isMultiply: stringMap[index].isMultiply,
        lables: item.data.map(label => ({
          name: label.name,
          value: label.id
        }))
      }))
    }).catch(err => {
      console.log(err)
    })
  },
  methods: {
    onLoad () {
      this.page++
      this.fetchList()
    },
    fetchList () {
      getMeetingList({
        page: this.page,
        page_size: 10,
        ordering: this.sortType === 'desc' ? '-meeting_year,-id' : 'meeting_year,-id',
        search: this.search,
        ...this.filterOpt,
        t: new Date().getTime()
      }).then(res => {
        console.log(res)
        this.list.push(...res.data)
        this.total = res.page.count
        this.loading = false
        if (this.list.length >= this.total) {
          this.finished = true
        }
      }).catch(err => {
        console.log(err)
      })
    },
    resetState () {
      this.list = []
      this.loading = false
      this.finished = false
      this.page = 0
      this.total = 0
      this.$refs.myList.check()
    },
    handleClick (item) {
      this.$router.push(`/meeting/detail?id=${item.id}`)
    },
    handleSort () {
      this.sortType = this.sortType === 'desc' ? 'asc' : 'desc'
      this.resetState()
    },
    filterHandle () {
      console.log('filterHandle')
    },
    handleFilter () {
      this.show = true
    },
    resetHandle () {
      console.log('resetHandle')
      this.filterOpt = {}
      this.show = false
      this.resetState()
    },
    okHandle (data) {
      if (JSON.stringify(data) === '{}') {
        this.filterOpt = {}
      } else {
        this.filterOpt = data
      }
      this.show = false
      this.resetState()
    },
    filterChange (val) {
      console.log(val)
    },
    handleClear () {
      console.log('handleClear')
      this.search = ''
      this.resetState()
    },
    handleSearch (e) {
      if (e.keyCode === 13) {
        this.resetState()
      }
    }
  },
  beforeRouteLeave (to, from) {
    if (to.path === '/meeting/detail') {
      this.$store.commit('catchComponents/GET_CATCHE_COMPONENTS', ['layout', 'Meeting'])
    } else {
      this.$store.commit('catchComponents/GET_CATCHE_COMPONENTS', [])
    }
  }
}
</script>

<style lang="less" scoped>
.meeting-page-wrap {
  .search-wrap {
    padding: 12px 16px;
    background: #0C86FE;

    .search {
      box-sizing: border-box;
      background: rgba(255,255,255,0.1);
      border-radius: 4px;
      padding: 7px 10px;
    }
  }

  .tool-wrap {
    display: flex;
    justify-content: space-between;
    padding: 12px 16px;
    border-bottom: 1px solid #E4E4E4;
    background-color: #fff;
    font-size: 14px;

    .total {
      color: #999;
    }
    .tool {
      display: flex;
      .tool-item {
        display: flex;
        align-items: center;
        &:nth-of-type(1) {
          margin-right: 16px;
        }

        & > span {
          margin-right: 8px;
          color: #333;
        }
      }
    }
  }

  .item-wrap {
    padding: 16px;
    background-color: #fff;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      left: 16px;
      bottom: 0;
      right: 0;
      height: 1px;
      background-color: #E4E4E4;
    }

    .logo {
      width: 100%;
      height: 62px;

      & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .title {
      margin-top: 16px;
      font-size: 16px;
      font-weight: 500;
      color: #333333;
    }

    .year {
      margin-top: 10px;
      color: #999999;
      font-size: 12px;
    }
  }
}
</style>
